export default class Lightbox {
  /**
   * Set scope, bind functions and get elements
   */
  constructor(elem) {
    // This binding is necessary to make `this` work in the callback
    this.boundFill = e => this.fill(e);
    this.boundToggle = e => this.toggle(e);

    // Set scope
    this.lightbox = elem;
    this.image = this.lightbox.querySelector('.lightbox__img');
    this.close = this.lightbox.querySelector('.lightbox__close');
    this.photos = document.querySelectorAll('.blocks__item__inner--lightbox');
  }

  /**
   * Bind all event listeners to appropiate elemens
   */
  init() {
    this.close.addEventListener('click', this.boundToggle, false);

    const { length } = this.photos;

    for (let i = 0; i < length; i += 1) {
      this.photos[i].addEventListener('click', this.boundFill, false);
    }
  }

  /**
   * Bind all event listeners to appropiate elemens
   */
  toggle(e) {
    e.preventDefault();

    this.lightbox.classList.toggle('open');
  }

  /**
   * Bind all event listeners to appropiate elemens
   */
  fill(e) {
    e.preventDefault();

    const img = e.currentTarget.querySelector('figure').innerHTML;
    this.image.innerHTML = img;

    this.close.click();
  }
}
