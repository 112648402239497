/**
 * Class to handle form submits.
 *
 * - Gets binded to form on render.
 * - Calls WordPress Ajax, which handles the validation and sending
 */
export default class Form {
  /**
   * Construct new object from form element
   */
  constructor() {
    /**
     * Bound functions to keep this reference
     */
    this.boundValidate = e => this.validate(e);
    this.boundValidateInput = (e, showError) => this.validateInput(e, showError);

    /**
     * Classnames
     */
    this.selector = 'form';
    this.field = `${this.selector}__field`;
    this.fieldError = `${this.field}--error`;
    this.fieldValid = `${this.field}--valid`;
    this.fieldFilled = `${this.field}--filled`;
    this.errorMsg = `${this.field}__error`;
    this.label = `${this.field}__label`;
    this.input = `${this.field}__input`;

    /**
     * Add submit listeners to all forms
     */
    this.forms = document.querySelectorAll(`.${this.selector}`);
    this.formsLength = this.forms.length;

    // Variable to track checks
    this.check = true;
  }

  /**
   * Loop over all forms to add validate function to submit
   */
  addListeners() {
    let i = 0;

    for (i; i < this.formsLength; i += 1) {
      const form = this.forms[i];
      const inputs = this.forms[i].querySelectorAll(`.${this.input}`);

      for (let j = 0; j < inputs.length; j += 1) {
        inputs[j].addEventListener('keyup', this.boundValidateInput, false);
        inputs[j].addEventListener('blur', e => this.boundValidateInput(e, true), false);
      }

      form.addEventListener('submit', this.boundValidate, false);
    }
  }

  /**
   * Loop over all forms to remove validate function to submit
   */
  removeListeners() {
    let i = 0;

    for (i; i < this.formsLength; i += 1) {
      const form = this.forms[i];
      const inputs = this.forms[i].querySelectorAll(`.${this.input}`);

      form.removeEventListener('submit', this.boundValidate, false);

      for (let j = 0; j < inputs.length; j += 1) {
        inputs[j].removeEventListener('keyup', this.boundValidateInput, false);
      }
    }
  }

  /**
   * Validation of single input field
   */
  validateInput(e, showError = false) {
    const elem = (e instanceof HTMLElement) ? e : e.target;
    const field = elem.parentNode.parentNode;
    const { value } = elem;
    const required = elem.getAttribute('required');

    if (required) {
      if (value.length > 0) {
        field.classList.add(this.fieldValid);
      } else {
        field.classList.remove(this.fieldValid);
      }
    }

    if (value.length > 0) {
      field.classList.add(this.fieldFilled);
    } else {
      field.classList.remove(this.fieldFilled);
    }

    if (showError) {
      const error = field.querySelector(`.${this.errorMsg}`);

      // Set check to false if input is not valid
      if (typeof elem.willValidate !== 'undefined') {
        if (!elem.checkValidity()) {
          // Check to false
          if (this.check) {
            this.check = false;
          }

          // Show error message
          if (error) {
            field.classList.add(this.fieldError);
          }
        } else if (error) {
          field.classList.remove(this.fieldError);
        }
      }
    }
  }

  /**
   * Quick validation to check if required fields are not left empty
   */
  validate(e) {
    // Prevent sending form
    e.preventDefault();
    const form = e.target;
    const inputs = form.querySelectorAll(`.${this.input}`);

    // Loop over inputs
    let i = 0;
    const { length } = inputs;

    for (i; i < length; i += 1) {
      const input = inputs[i];

      this.validateInput(input, true);
    }

    // Send form if check is passed
    if (this.check) {
      form.submit();
    }
  }
}
