export default class Menu {
  /**
   * Set scope, bind functions and get elements
   */
  constructor() {
    // Set scope
    const scope = document.body;

    // This binding is necessary to make `this` work in the callback
    this.boundToggleMenu = e => this.toggleMenu(e);
    this.boundToggleSubMenu = e => this.toggleSubMenu(e);

    // Get necessary elements
    this.menuIcon = scope.querySelector('.head__hamburger');
    this.menuElems = scope.querySelectorAll('.js-menu');
    this.menuElemsLength = this.menuElems.length;
    this.subMenuLinks = scope.querySelectorAll('.js-submenu-link');
    this.subMenuElems = scope.querySelectorAll('.js-submenu');
    this.subMenuElemsLength = this.subMenuElems.length;
  }

  /**
   * Bind all event listeners to appropiate elemens
   */
  init() {
    // Toggle menu on hamburger icon click
    if (this.menuIcon) {
      this.menuIcon.addEventListener('click', this.boundToggleMenu, false);
    }

    // Toggle submenu on main item click on mobile
    const { length } = this.subMenuLinks;

    for (let i = 0; i < length; i += 1) {
      const elem = this.subMenuLinks[i];

      elem.addEventListener('click', this.boundToggleSubMenu, false);
    }
  }

  /**
   * Show or hide the overlay menu
   */
  toggleMenu(e) {
    e.preventDefault();

    document.body.classList.toggle('js-overlay-menu-open');

    for (let i = 0; i < this.menuElemsLength; i += 1) {
      const elem = this.menuElems[i];

      elem.classList.toggle('js-overlay-menu-open');
    }

    // Always reset submenu
    for (let i = 0; i < this.subMenuElemsLength; i += 1) {
      const elem = this.subMenuElems[i];

      elem.classList.remove('js-overlay-submenu-open');
    }

    if (this.subMenuLink) {
      this.subMenuLink.classList.remove('js-overlay-submenu-open');
    }
  }

  /**
   * Show or hide a submenu on mobile
   */
  toggleSubMenu(e) {
    if (window.innerWidth < 900) {
      e.preventDefault();

      // Toggle submenu on main item click on mobile
      const { length } = this.subMenuLinks;

      for (let i = 0; i < length; i += 1) {
        const elem = this.subMenuLinks[i];

        if (!elem.classList.contains('head__overlay__back')) {
          elem.classList.remove('js-overlay-submenu-open');
        }
      }

      const target = e.currentTarget;
      if (!target.classList.contains('head__overlay__back')) {
        this.subMenuLink = target;
        this.subMenuLink.classList.toggle('js-overlay-submenu-open');
      }

      for (let i = 0; i < this.subMenuElemsLength; i += 1) {
        const elem = this.subMenuElems[i];

        elem.classList.toggle('js-overlay-submenu-open');
      }
    }
  }
}
