// Libraries
import animateScrollTo from 'animate-scroll-to';
import Masonry from 'masonry-layout';
import lazyLoadSrcSet from 'lazyload-srcset';
import Lightbox from './Lightbox';
import Menu from './Menu';
import Form from './Form';

const masonry = (grid) => {
  /* eslint-disable no-unused-vars */

  const msnry = new Masonry(grid, {
    itemSelector: '.masonry__col',
    columnWidth: '.masonry__col',
    percentPosition: true,
    transitionDuration: 0,
  });

  /* eslint-enable no-unused-vars */
};

/**
 * Get element top
 */
const getElemTop = (elem) => {
  let top = 0;

  // Check if element exists
  if (elem.nodeType === 1) {
    // Calculate top
    const box = elem.getBoundingClientRect();
    const { body } = document;

    // Get top
    const scrollTop = window.pageYOffset || document.scrollTop || body.scrollTop;
    const clientTop = document.clientTop || body.clientTop || 0;
    top = Math.round((box.top + scrollTop) - clientTop);

    // Loop over fixed elements to remove height from scroll position
    const fixedElems = document.querySelectorAll('[data-fixed]');

    if (fixedElems) {
      const fixedElemsLength = fixedElems.length;

      for (let i = 0; i < fixedElemsLength; i += 1) {
        const fixedElem = fixedElems[i];

        if (fixedElem.nodeType === 1) {
          top -= fixedElem.offsetHeight;
        }
      }
    }
  }

  return top;
};

/**
 * Scroll to hash location
 */
const toHash = (e) => {
  e.preventDefault();

  const target = document.querySelector(e.currentTarget.getAttribute('href'));
  const top = getElemTop(target);

  animateScrollTo(top, 500);
};

const headerClass = () => {
  const header = document.querySelector('.head');
  const scrollTop = window.pageYOffset || document.scrollTop || document.body.scrollTop;

  if (scrollTop > 0) {
    header.classList.add('border');
  } else {
    header.classList.remove('border');
  }
};

/**
 * Bind all functions on pageload
 */
const init = () => {
  // Header
  headerClass();
  window.addEventListener('scroll', headerClass, { capture: false, passive: true });

  // Menu
  const menu = new Menu();
  menu.init();

  // Scroll actions
  const hashLinks = document.querySelectorAll('.js-hash');
  const hashLinksCount = hashLinks.length;

  for (let i = 0; i < hashLinksCount; i += 1) {
    const link = hashLinks[i];
    link.addEventListener('click', toHash, false);
  }

  // Form
  const form = new Form();
  form.addListeners();

  // Lightbox
  const lightbox = document.querySelector('.lightbox');
  if (lightbox) {
    const lightboxInstance = new Lightbox(lightbox);
    lightboxInstance.init();
  }

  // Masonry
  const grid = document.querySelector('.masonry');
  if (grid) {
    masonry(grid);
  }

  // Lazyload
  lazyLoadSrcSet();
};

window.addEventListener('load', init, false);
